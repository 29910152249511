export * from "./endpoints";
export * from "./errors";
export { useCache } from "./axios";
import { axios } from "./axios";

export const storage = axios.storage;

export const clearCache = async () => {
  // Get all entries from sessionStorage that start with "hw-cache:"
  // and remove them from the storage
  const deletePromises = Object.keys(sessionStorage)
    .filter(key => key.startsWith("hw-cache:"))
    .map(key => {
      const cacheKey = key.replace("hw-cache:", "");

      return axios.storage.remove(cacheKey);
    });

  await Promise.allSettled(deletePromises);
};

<template>
  <component
    :is="componentType"
    v-bind="properties"
    ref="buttonRef"
    :disabled="props.disabled"
    :class="[
      'group inline-flex items-center gap-x-3 px-3 py-2 text-sm font-light leading-6 outline-none transition duration-75',
      props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      props.borderClass,
      buttonColor,
      alignment
    ]"
  >
    <span v-if="'default' in slots" :class="props.textClass">
      <span :class="{ 'inline-block': props.icon }">
        <slot></slot>
      </span>
    </span>
    <unicon
      v-if="props.icon"
      :name="props.icon"
      :class="['-mx-1 size-6 p-0.5', iconColor, iconClass]"
      aria-hidden="true"
    />
  </component>
</template>

<script lang="ts">
interface Props extends /* @vue-ignore */ ButtonHTMLAttributes {
  icon?: string;
  color?: "blue" | "white" | "red";
  disabled?: boolean;
  align?: "left" | "center" | "right"; // Only used if there is no icon
  to?: RouteLocationRaw;
  href?: string;
  textClass?: string;
  iconClass?: string;
  borderClass?: string;
}
</script>

<script setup lang="ts">
import { tw } from "@/utils";
import { ref, computed, useSlots, type ButtonHTMLAttributes } from "vue";
import type { RouteLocationRaw } from "vue-router";

defineExpose({
  focus: () => buttonRef.value.focus()
});

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  color: "white",
  textClass: tw``,
  iconClass: tw``,
  borderClass: tw`border`
});

const slots = useSlots();

const buttonRef = ref();

const buttonColor = computed(() => {
  const color = {
    blue: {
      default: tw`border-blue-light bg-blue-light text-white hover:bg-blue-light/[.85] focus:bg-blue-light/[.85] active:bg-blue-light/75`,
      disabled: tw`border-blue-lighter bg-blue-lighter text-black`
    },
    red: {
      default: tw`border-red bg-red text-white hover:bg-red/[.85] focus:bg-red/[.85] active:bg-red/75`,
      disabled: tw`border-red bg-red bg-opacity-70 text-black`
    },
    white: {
      default: tw`border-tw-grey bg-white text-black hover:bg-athens focus:bg-athens active:bg-cloudy`,
      disabled: tw`border-tw-grey bg-cloudy text-black`
    }
  };

  return props.disabled ? color[props.color].disabled : color[props.color].default;
});

const iconColor = computed(() => {
  const color = {
    blue: tw`fill-white`,
    red: tw`fill-white`,
    white: tw`fill-black`
  };

  return props.disabled ? color.white : color[props.color];
});

const alignment = computed(() => {
  switch (props.align) {
    case "left":
      return tw`justify-start text-left`;
    case "center":
      return tw`justify-center text-center`;
    case "right":
      return tw`justify-end text-right`;
  }

  if (!props.icon) return tw`justify-center text-center`;

  return tw`justify-between text-left`;
});

const componentType = computed(() => {
  if (props.to) return "router-link";
  if (props.href) return "a";

  return "button";
});

const properties = computed(() => {
  if (props.to) return { to: props.to };
  if (props.href) return { href: props.href };

  return {};
});
</script>

import { checkLicenseAndRoute, routeMeta, hasAccess } from "@/utils/routing";
import { AccessLevel, License } from "@/constants";
import { useBuildingStore } from "@/stores/building";
import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";

const HealthLandingPage = () => import("@/views/HealthLandingPage.vue");
const HealthBuildingLive = () => import("@/views/HealthBuildingLive.vue");
const HealthBuildingLiveOld = () => import("@/views/HealthBuildingLiveOld.vue");
const HealthBuildingSensors = () => import("@/views/HealthBuildingSensors.vue");
const HealthFloorOverview = () => import("@/views/HealthFloorOverview.vue");
const HealthFloorDetail = () => import("@/views/HealthFloorDetail.vue");
const HealthSpaceOverview = () => import("@/views/HealthSpaceOverview.vue");
const HealthSpaceDetail = () => import("@/views/HealthSpaceDetail.vue");
const HealthSpaceLive = () => import("@/views/HealthSpaceLive.vue");
const HealthSpaceComparison = () => import("@/views/HealthSpaceComparison.vue");

async function routeToSpace(to: RouteLocationNormalized): Promise<true | RouteLocationRaw> {
  const building = useBuildingStore();

  return (
    !!building.getSpace(to.params.id as Guid) || {
      path: "/404",
      query: { fromUrl: to.fullPath }
    }
  );
}

async function routeToFloor(to: RouteLocationNormalized): Promise<true | RouteLocationRaw> {
  const building = useBuildingStore();

  return (
    !!building.getFloor(to.params.id as Guid) || {
      path: "/404",
      query: { fromUrl: to.fullPath }
    }
  );
}

const routes: RouteRecordRaw[] = [
  {
    path: "/buildings/:buildingId/health",
    name: "HealthLandingPage",
    component: HealthLandingPage,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      queryParams: []
    }),
    beforeEnter: [hasAccess, to => checkLicenseAndRoute(to, License.Health, "HealthFloorOverview")]
  },
  {
    path: "/public/buildings/:buildingId/live",
    name: "HealthBuildingPublic",
    component: HealthBuildingLive,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Public,
      queryParams: ["projectId"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/live-alt",
    name: "HealthBuildingLive",
    component: HealthBuildingLive,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      queryParams: []
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/live",
    name: "HealthBuildingLiveOld",
    component: HealthBuildingLiveOld,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      queryParams: []
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/sensors",
    name: "HealthBuildingSensors",
    component: HealthBuildingSensors,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building"],
      queryParams: []
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/floors",
    name: "HealthFloorOverview",
    component: HealthFloorOverview,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/floors/:id",
    name: "HealthFloorDetail",
    component: HealthFloorDetail,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building", "floors"]
    }),
    beforeEnter: [hasAccess, routeToFloor]
  },
  {
    path: "/buildings/:buildingId/spaces",
    name: "HealthSpaceOverview",
    component: HealthSpaceOverview,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/spaces/:id",
    name: "HealthSpaceDetail",
    component: HealthSpaceDetail,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building", "floor"]
    }),
    beforeEnter: [hasAccess, routeToSpace]
  },
  {
    path: "/public/buildings/:buildingId/spaces/:id/live",
    name: "HealthSpacePublic",
    component: HealthSpaceLive,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Public,
      queryParams: ["projectId"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/spaces/:id/live",
    name: "HealthSpaceLive",
    component: HealthSpaceLive,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      queryParams: []
    }),
    beforeEnter: [hasAccess, routeToSpace]
  },
  {
    path: "/buildings/:buildingId/reports/compare-spaces",
    redirect: { name: "HealthSpaceComparison" }
  },
  {
    path: "/buildings/:buildingId/compare-spaces",
    name: "HealthSpaceComparison",
    component: HealthSpaceComparison,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.Health,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;

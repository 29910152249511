import { useAuthStore } from "@/stores/auth";
import { hasAccess, routeMeta } from "@/utils/routing";
import type { RouteRecordRaw } from "vue-router";
import { AccessLevel } from "@/constants";

const UserPreferences = () => import("@/views/UserPreferences.vue");
const UserWelcome = () => import("@/views/UserWelcome.vue");
const UserLogin = () => import("@/views/UserLogin.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/preferences",
    name: "UserPreferences",
    component: UserPreferences,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/welcome",
    name: "UserWelcome",
    component: UserWelcome,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLogin,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Public
    }),
    beforeEnter: [
      hasAccess,
      to => {
        const auth = useAuthStore();

        if (auth.isLoggedIn) {
          const path = to.query.redirect || "/";
          delete to.query.redirect;

          return { path, query: to.query };
        }
      }
    ]
  }
];

export default routes;

import { routeMeta, checkLicenseAndRoute, hasAccess } from "@/utils/routing";
import { AccessLevel, License } from "@/constants";
import type { RouteRecordRaw } from "vue-router";

const BmsLandingPage = () => import("@/views/BmsLandingPage.vue");
const BmsSystems = () => import("@/views/BmsSystems.vue");
const BmsSystemsDetails = () => import("@/views/BmsSystemsDetails.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/buildings/:buildingId/smart-building",
    name: "BmsLandingPage",
    component: BmsLandingPage,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser
    }),
    beforeEnter: [hasAccess, to => checkLicenseAndRoute(to, License.Bms, "BmsSystems")]
  },
  {
    path: "/buildings/:buildingId/reports/building-systems-activity",
    redirect: { name: "BmsSystems" }
  },
  {
    path: "/buildings/:buildingId/building-systems",
    name: "BmsSystems",
    component: BmsSystems,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      requiredLicense: License.Bms,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/building-systems/details",
    name: "BmsSystemsDetails",
    component: BmsSystemsDetails,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      requiredLicense: License.Bms,
      breadcrumbs: ["building", "bms"]
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;
